import React from "react";
import "../App.css";
import Main from "../containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme } from "../theme";
import { GlobalStyles } from "../global";


export default () => {
  return (
    <ThemeProvider theme={chosenTheme}>
      <>
        <GlobalStyles />
        <div>
          <Main theme={chosenTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

