import React, { Component } from "react";
// import { Route, Switch, HashRouter } from "react-router-dom";
import { Router } from "@reach/router"
import Home from "../pages/home/HomeComponent";
import Education from "../pages/education/EducationComponent";
import Experience from "../pages/experience/Experience";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/all";
import { settings } from "../portfolio.js";
import {theme} from "../theme.js"

export default class Main extends Component {
  render() {
    // console.log(theme);
    if (settings.isSplash) {
      return (
        <div>

          <Router basename="/">
            <Home theme={theme} path="/" />
            <Home theme={theme} path="/home" />
            <Experience theme={theme} path="/experience" />
            <Education theme={theme} path="/education" />
            <Contact theme={theme} path="/contact" />
            <Projects theme={theme} path="/projects" />



          </Router>
        </div>
      );
    } else {
      return (
        <div>
          <Router basename="/">
            <Home theme={theme} path="/" />
            <Home theme={theme} path="/home" />
            <Experience theme={theme} path="/experience" />
            <Education theme={theme} path="/education" />
            <Contact theme={theme} path="/contact" />
            <Projects theme={theme} path="/projects" />
          </Router>
        </div>
      );
    }
  }
}